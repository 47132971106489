export default {
  DROPDOWN_EDIT_ACCOUNT: "Edit Account",
  DROPDOWN_UPDATE_PW: "Update Password",
  DROPDOWN_LOGOUT: "Logout",
  DROPDOWN_DUTCH: "Dutch",
  DROPDOWN_ENGLISH: "English",
  DROPDOWN_SORT_A_Z: "A_Z",
  DROPDOWN_SORT_Z_A: "Z_A",
  DROPDOWN_SORT_BY_POPULARITY: "Popularity",
  DROPDOWN_ALL: "All",
  DROPDOWN_READ: "Read",
  DROPDOWN_UNREAD: "Unread",

  DROPDOWN_BLOGS: "Blogs",
  DROPDOWN_CONTACT_US: "Contact Us",
  DROPDOWN_ABOUT_US: "About Us",
  DROPDOWN_SOLLICITEER_DIRECT: "Apply Now",


  DROPDOWN_ACCOUNT: "Account",
  DROPDOWN_SETTINGS: "Settings",
  DROPDOWN_FAQS: "FAQ’s",
  DROPDOWN_FINANCE: "finance",
  DROPDOWN_DELETE_ACCOUNT: "Delete Account",
  DROPDOWN_STATUS_VERIFICATION: "Status Verification",
  DROPDOWN_STATUS_VERIFIED: "Verified",

  DROPDOWN_MUTE_NOTIFICATIONS: "Mute Notifications",
  DROPDOWN_UNMUTE_NOTIFICATIONS: "Unmute Notifications",
  DROPDOWN_BLOCK: "Block",
  DROPDOWN_UNBLOCK: "Unblock",
  DROPDOWN_DELETE_CHATE: "Delete Chat",
  DROPDOWN_REPORT: "Report",
  DROPDOWN_EDIT: "Edit",
  DROPDOWN_DELETE: "Delete",
  DROPDOWN_COMPLETE_CONTRACT: "Complete Contract",
  DROPDOWN_CANCEL_CONTRACT: "Cancel Contract",
  DROPDOWN_PAUSE_CONTRACT: "Pause Contract",
  DROPDOWN_RESUME_CONTRACT: "Resume Contract",
  DROPDOWN_VIEW_JOB_DETAIL: "View Job Detail",
  DROPDOWN_EXTEND_JOB_CONTRACT: "Extend Job Contract",
  DROPDOWN_REPEAT_JOB_CONTRACT: "Repeat Job Contract",
  DROPDOWN_VIEW_WORKER_PROFILE: "View Worker Profile",
  DROPDOWN_CHANGE_HOURLY_RATE: "Change Hourly Rate",
  // new one
  DROPDOWN_CHANGE_PASSWORD:"Change Password",

};
