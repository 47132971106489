import {
  REGISTRATION_FORMS,
  authWithTypes,
  locale,
} from "./../../../types/forms.d";
import { AxiosResponse } from "axios";
import { EMAIL_VERIFICATION, LOGIN_FORMS } from "types/forms";
import apiRequest from "utils/axios";
import {
  ILoginResponse,
  IRegisterEmailResponse,
  IVerifyLinkData,
  IVerifyLinkResponse,
  IRefreshTokenResponse,
  ISocialLoginResponse,
} from "./types";
import { ApplicationFormProps } from "components/organisms/SolicitedApplicationForm/@types";

export const loginApi = async (
  data: LOGIN_FORMS
): Promise<AxiosResponse<ILoginResponse, any>> => {
  const response = await apiRequest({
    method: "post",
    url: "/login",
    data,
  });
  return response;
};

// "/email-verification" to use to verifiy email before registration

export const registerEmailApi = async (
  data: EMAIL_VERIFICATION,
  locale: string,
  FOR?: string | null | undefined
): Promise<AxiosResponse<IRegisterEmailResponse, any>> => {
  let path = `/register?`;
  if (locale) path += `locale=${locale}&`;
  if (FOR) path += `accountFor=${FOR}&`;

  const response = await apiRequest({
    method: "post",
    // url: `/register`,
    url: path,
    data,
  });
  return response;
};


export const ApplicationSubmitApi = async (
  data: ApplicationFormProps
): Promise<AxiosResponse<IVerifyLinkResponse, any>> => {
  // console.log("data", data);
  const response = await apiRequest({
    method: "post",
    url: "/send-mail",
    data,
  });
  return response;
};


// "/link-verification"

export const registerLinkApi = async (
  data: IVerifyLinkData
): Promise<AxiosResponse<IVerifyLinkResponse, any>> => {
  // console.log("data", data);
  const response = await apiRequest({
    method: "post",
    url: "/link-verification",
    data,
  });
  return response;
};

// /reset/request

export const resetPasswordRequestApi = async (
  data: EMAIL_VERIFICATION,
  locale: string
): Promise<AxiosResponse<IRegisterEmailResponse, any>> => {
  console.log("resetPasswordRequest", data);
  const response = await apiRequest({
    method: "post",
    url: `/reset/request?locale=${locale}`,
    data,
  });
  return response;
};

export const updatePassworApi = async (data: {
  password: string;
  token: string;
}): Promise<AxiosResponse<IRegisterEmailResponse, any>> => {
  console.log("updatePassworApi", data);
  const response = await apiRequest({
    method: "post",
    url: "/reset/password",
    data,
  });
  return response;
};

//   /complete/register

export const completeRegisterApi = async (
  data: REGISTRATION_FORMS
): Promise<AxiosResponse<ILoginResponse, any>> => {
  console.log("completeRegisterApi", data);
  const response = await apiRequest({
    method: "post",
    url: "/complete/register",
    data,
  });
  return response;
};

// refreshToken

export const refreshTokenApi = async (
  data: { deviceId: string },
  token?: string | null
): Promise<AxiosResponse<IRefreshTokenResponse, any>> => {
  console.log("refreshTokenApi", data, token);
  const response = await apiRequest({
    method: "post",
    url: "/refreshToken",
    data,
    token,
  });
  return response;
};

// /social/authentication

export const socialAuthticationApi = async (
  data: { authWith: authWithTypes },
  token: string
): Promise<AxiosResponse<ISocialLoginResponse, any>> => {
  const response = await apiRequest({
    method: "post",
    url: "/social/authentication",
    // url: "/refreshToken",
    data,
    token,
  });
  return response;
};

//  /logout

export const logoutApi = async (
  deviceId: string,
  token: string
): Promise<AxiosResponse<IRegisterEmailResponse, any>> => {
  console.log("logoutApi", deviceId, "token", token);

  const response = await apiRequest({
    method: "put",
    url: "/logout",
    token,
    data: {
      deviceId,
    },
  });
  return response;
};
