export default {
  PLACEHOLDER_EMAIL: "Email ID",
  PLACEHOLDER_PASSWORD: "Password",
  PLACEHOLDER_CONFIRM_PASSWORD: "Confirm Password",
  PLACEHOLDERS_SELECT: "Select",
  PLACEHOLDERS_SEARCH: "Search",
  PLACEHOLDERS_FIRST_NAME: "First Name",
  PLACEHOLDERS_NAME: "Name",

  PLACEHOLDERS_LAST_NAME: "Last Name",
  PLACEHOLDERS_BIRTHDAY: "Birthday",
  PLACEHOLDERS_STREET: "Street",

  PLACEHOLDERS_ADDRESS_TITLE: "Address Title",

  PLACEHOLDERS_ZIP: "Zip",
  PLACEHOLDERS_CITY: "City",
  PLACEHOLDERS_COUNTRY: "Country",
  PLACEHOLDERS_KVK_NUMBER_OPTIONAL: "KVK number (Optional)",
  PLACEHOLDERS_COMPANY_NAME_OPTIONAL: "Company name (Optional)",
  PLACEHOLDERS_PLACE: "Place",
  PLACEHOLDERS_ACCOUNT_HOLDER_NAME: "Account Holder Name",
  PLACEHOLDERS_ENTER_CONTRACT: "Enter Contract",
  PLACEHOLDERS_IBAN: "IBAN",
  PLACEHOLDERS_ACCOUNT_NUMBER: "Account Number",
  PLACEHOLDERS_BTW_NUMBER: "BTW identification Number",
  PLACEHOLDERS_BANK_NAME: "Bank Name",
  PLACEHOLDERS_COMPANY_NAME: "Company Name",
  PLACEHOLDERS_EMPLOYEES: "Employees head count",
  PLACEHOLDERS_POSITION: "Position",
  PLACEHOLDERS_BUSINESS_BRANCH: "Business Branch",
  PLACEHOLDERS_ADD_DESCRIPTION: "Add description",
  PLACEHOLDERS_PERPOSAL: "Job Perposal",
  PLACEHOLDERS_MESSAGE: "Message",
  PLACEHOLDERS_TELL_SOMETHING_ABOUT_YOURSELF: "Tell something about yourself",
  PLACEHOLDERS_ACCOUNT_HEAD_LINE: "Account Headline",
  PLACEHOLDERS_ID_NUMBER: "ID Number",
  PLACEHOLDERS_SALARY_HOURLY_RATE: "Salary/Hourly rate",
  PLACEHOLDERS_REASON: "Reason",
  PLACEHOLDERS_JOB_TITLE: "Job title",
  PLACEHOLDERS_EDUCATION_TITLE: "Education title",
  PLACEHOLDERS_ENTER_CATEGORY: "Enter Category",
  PLACEHOLDERS_ENTER_BRANCH: "Enter Branch",
  PLACEHOLDERS_ADD_SKILLS: "Add Skills",
  PLACEHOLDERS_ADD_REASON: "Add Reason",
  PLACEHOLDERS_TASK_NAME: "Enter task name",
  PLACEHOLDERS_9_00: "09:00",
  PLACEHOLDERS_11_00: "11:00",
  PLACEHOLDERS_I_WILL_WORK_WHOLE_DAY: "I will work whole day for office ...",
  PLACEHOLDERS_JOB_NAME: "Job Name",
  PLACEHOLDERS_JOB_DESCRIPTION: "Message for worker",
  PLACEHOLDERS_REPORT_DESCRIPTION: "Report Description",
  PLACEHOLDERS_STARTING_DATE: "Starting Date",
  // repeatEndingDate
  PLACEHOLDERS_REPEAT_ENDING_DATE: "Repeat Ending Date",
  PLACEHOLDERS_START_TIME: "Start Time",
  PLACEHOLDERS_END_TIME: "End Time",
  PLACEHOLDERS_ENDING_DATE: "Ending Date",
  PLACEHOLDERS_TIME_START: "Time Start",
  PLACEHOLDERS_TIME_END: "Time End",
  PLACEHOLDERS_MONTHLY_SALARY_PRICE_PER_HOUR: "Monthly Salary/Price per Hour",
  PLACEHOLDERS_BREAK_TIME: "Break Time in minutes",
  PLACEHOLDERS_REVIEW: "Review for worker",
  PLACEHOLDERS_TRAVEL_EXPENSE: "Travel Expense",
  PLACEHOLDERS_WORKERS_NEEDED: "Workers Needed",
  PLACEHOLDERS_CLOTHES: "Clothes",
  PLACEHOLDERS_CLOTHES_DESCRIPTION: "Clothes Description",
  PLACEHOLDERS_ADDRESS: "Address",
  PLACEHOLDERS_GOOGLE_AUTFILL_ADDRESS: "Google Autfill Address",
  PLACEHOLDERS_PHONE_NUMBER: "Enter your phone",
  PLACEHOLDERS_JOB_TTTLE: "Enter Job Title",
  PLACEHOLDERS_ENTER_COMPANY_NAME: "Enter Company Name",
  PLACEHOLDERS_ENTER_DEGREE_TITLE: "Enter Degree Title",
  PLACEHOLDERS_ENTER_INSTITUTE_NAME: "Enter Institute Name",
  PLACEHOLDERS_JOB_PROPOSAL: "Job Proposal",
  PLACEHOLDERS_ENTER_SHIFT: "Enter Shift",
  PLACEHOLDERS_SELECT_BRANCH: "Select Branch",
  PLACEHOLDERS_SELECT_EMPLOYEE: "Select Employee",
  PLACEHOLDERS_ADD_LINKEDIN_URL: "Add linkedIn url",
  PLACEHOLDERS_ADD_SKYPE_URL: "Add skype url",

  PLACEHOLDER_PW: "Enter your password",
  PLACEHOLDER_NUM_OF_ITEM: "Enter number of items",
  PLACEHOLDER_SELECT: "Please select",

  PLACEHOLDER_ENTER_PHONE: "Please enter your phone number",
  PLACEHOLDER_ENTER_POSTAL_CODE: "Please enter your postal code",
  PLACEHOLDER_ENTER_ADDRESS: "Please enter your address",

  PLACEHOLDER_SEARCH: "Search here...",
  PLACEHOLDER_SEND_GOVERNMENT_ISSUED_LINK:
    "Send goverment issued verified link to employee",
  PLACEHOLDER_ADD_BRANCH_NAME: "Please add branch name",
  PLACEHOLDER_SELECT_BRANCH: "Please Select Branch",
  PLACEHOLDER_ADD_CATEOGRY_NAME: "Please add category name",
  PLACEHOLDER_ADD_SKILL_NAME: "Please add skill name",

  // new one
  PLACEHOLDER_CURRENT_PASSWORD:"Current Password",
  PLACEHOLDER_NEW_PASSWORD:"New Password",
};
