export default {
  ERROR_EMAIL_REQUIRED: "Email is required",
  ERROR_EMAIL_INVALID: "Email is invalid",
  ERROR_PW_REQUIRED: "Password is required",
  ERROR_PW_INVALID: "Password is invalid",

  ERROR_APPLICATION_SUBMITTED: "Application could not be submitted",

  ERROR_PW_FORMAT:
    "Password must contain at least one uppercase, one lowercase, one number and one special character",
  ERROR_PW_LENGTH: "Password must be at least 8 characters",
  ERROR_PW_NOT_MATCH: "Passwords do not match",
  ERROR_TITLE_REQUIRED: "Title is required",
  ERROR_JOB_DESCRIPTION_REQUIRED: "Job Description is required",
  ERROR_WORKER_TYPE_REQUIRED: "Worker type is required",
  ERROR_HIRING_URGENCY_REQUIRED: "Hiring Urgency Level is required",
  ERROR_WAGE_REQUIRED: "Wage is required",
  ERROR_ADDRESS_REQUIRED: "Address is required",
  ERROR_CLOTHS_REQUIRED: "Cloths Description is required",
  ERROR_HOURLY_RATE: "Hourly rate is required",
  ERROR_TRAVEL_EXPENSE_REQUIRED: "Travel expense is required",
  ERROR_WORKER_REQUIRED: "Number of Workers is required",
  ERROR_TIME_TO_START_REQUIRED: "Time to start is required",
  ERROR_TIME_TO_START_FORMAT: "Time format must be 09:00",
  ERROR_TIME_TO_END_REQUIRED: "Time to end is required",
  ERROR_TIME_TO_END_NOT_GREATER: "Time to end must be greater",
  ERROR_STARTING_DATE_REQUIRED: "Starting Date is required",
  ERROR_STARTING_DATE_PAST: "Starting Date should not be past date",
  ERROR_ENDING_DATE_REQUIRED: "Ending Date is required",
  ERROR_REPEATED_DAYS_REQUIRED: "Repeating days is required",
  ERROR_REPEATED_WEEK_REQUIRED: "Repeating week is required",
  ERROR_ENDING_DATE_PAST: "Ending Date should not be past date",
  ERROR_ENDING_DATE_BEFORE_STARTING_DATE:
    "Ending Date must be greater than Starting Date",
  ERROR_BREAK_TIME_REQUIRED: "Break time is required",
  ERROR_FIRSTNAME_REQUIRED: "First name is required",
  ERROR_FIRSTNAME_INVALID: "First name is invalid",
  ERROR_TITLE_INVALID: "Title is invalid",
  ERROR_JOB_DESCRIPTION_INVALID: "Job Description is invalid",
  ERROR_LASTNAME_REQUIRED: "Last name is required",
  ERROR_LASTNAME_INVALID: "Last name is invalid",
  ERROR_DOB_REQUIRED: "Date of birth is required",
  ERROR_DOB_INVALID: "Date of birth is invalid",
  ERROR_COMPANY_NAME_REQUIRED: "Company name is required",
  ERROR_EMPLOYEE_REQUIRED: "Employee head count is required",
  ERROR_EMPLOYEE_INVALID: "Employee is invalid",
  ERROR_POSITION_REQUIRED: "Position is required",
  ERROR_POSITION_INVALID: "Position is invalid",
  ERROR_PHONE_INVALID: "Phone is invalid",
  ERROR_BRANCH_REQUIRED: "Branch is required",

  ERROR_STREET_REQUIRED: "Street is required",
  ERROR_STREET_INVALID: "Street is invalid",
  ERROR_ZIP_REQUIRED: "Zip is required",
  ERROR_ZIP_INVALID: "Zip is invalid",
  ERROR_PLACE_REQUIRED: "Place is required",
  ERROR_CITY_REQUIRED: "City is required",
  ERROR_COUNTRY_REQUIRED: "Country is required",

  ERROR_PLACE_INVALID: "Place is invalid",
  ERROR_SHIFT_REQUIRED: "Shift is required",

  ERROR_MOBILE_REQUIRED: "Mobile number is Required",

  ERROR_ACCOUNT_HOLDER_NAME_REQUIRED: "Account holder name is required",
  ERROR_ACCOUNT_NUMBER_REQUIRED: "Account number is required",
  ERROR_BANK_NAME_REQUIRED: "Bank name is required",
  ERROR_IBAN_REQUIRED: "IBAN is required",
  ERROR_IBAN_FORMAT: "IBAN should be in correct format",
  ERROR_UNKNOWN: "Something went wrong",

  ERROR_CATEGORY_REQUIRED: "Category is required",
  ERROR_REPEATED_REQUIRED: "Job Repetation is required", // ERROR_REPEATED_REQUIRED is required",
  ERROR_FILL_REQUIRED_THE_FIELDS: "Fill in the required fields",
  ERROR_REPORT_REASON: "Report reason is required",
  ERROR_REPORT_DESCRIPTION: "Report reason is required",
  ERROR_MAX_THREE_CATEGORIES: "Maximum 3 categories can be selected",

  // : "Front and back of the ID card is required",

  ERRORS_ID_CARD_REQUIRED: "Front and back of the ID card is required",
  ERROR_TASK_REQUIRED: "Task is required",
  ERROR_DATE_REQUIRED: "Date is required",
  ERROR_STATUS_REQUIRED: "Status is required",
  ERROR_RATING: "Rating is required",
  ERROR_REVIEW: "Review is required",
  ERROR_AVAILABILITY_REQUIRED: "Availability is required",
  ERROR_TIMEFRAME_REQUIRED: "Timeframe is required",
  ERROR_INVALID_AVAILABILITY: "Invalid availability",
  ERROR_INVOICE_PERIOD: "please secelection invoice period",
  ERROR_INVALID_DATE: "Please select dates",
  ERROR_INVALID_TIMEFRAME: "Invalid timeframe",
  ERROR_INVALID_START_TIMEFRAME: "End time must be greater than start time",
  ERROR_INVALID_END_TIMEFRAME: "Invalid end timeframe",

  ERROR_PERPOSAL_DESCRIPTION_REQUIRED: "Proposal description is required",
  ERROR_PERPOSAL_DESCRIPTION_INVALID: "Proposal description is invalid",

  branch_not_allowed:
    "Your organization is not allowed to post job for the branch you selected",

  ERROR_INVALID_PW:
    "Password must be at least 8 characters long and must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
  ERROR_INVALID_PW_WEAK:
    "Password contain alphabate and number and length must be 6 to 12",
  ERROR_REQUIRE_PW: "Password is required",
  ERROR_REQUIRE_EMAIL: "Email is required",
  ERROR_REQUIRE_NAME: "Name is required",
  ERROR_REQUIRE_Maker: "Maker is required",
  ERROR_REQUIRE_EMISSION: "Emission is required",
  ERROR_INVALID_EMAIL: "Invalid Email",
  ERROR_INVALID_PROVINCE: "Invalid Province",
  ERROR_PLEASE_TYPE: "Please type here...",
  ERROR_INVALID_ADMIN_NAME: "Admin name length must be more than 3",
  ERROR_LOGIN_FAIL: "Login failed",
  ERROR_SESSION_EXPIRE: "Session expired",
  ERROR_NETWORK: "Network error occured",
  ERROR_WRONG_CRED: "Your credentials do not match",
  ERROR_UNKOWN: "Something went wrong. Please try again later.",
  ERROR_INVALID_BRANCH: "Invalid Branch",
  ERROR_INVALID_CATEGORY: "Invalid Category",
  ERROR_INVALID_SKILLSET: "Invalid Skillset",

  ERROR_CU_PW_REQUIRED:"Please Enter your cuurent password",
  ERROR_NEW_PW_REQUIRED:"Please Enter your new password",
  ERROR_CONFIRM_PW_REQUIRED:"Please Enter your confirm password",
};
