export default {
  PLACEHOLDER_EMAIL: "E-mailadres",
  PLACEHOLDER_PASSWORD: "Wachtwoord",
  PLACEHOLDER_CONFIRM_PASSWORD: "Wachtwoord",
  PLACEHOLDERS_SELECT: "Selecteer",
  PLACEHOLDERS_SEARCH: "Zoekopdracht",
  PLACEHOLDERS_FIRST_NAME: "Voornaam",
  PLACEHOLDERS_NAME: "Naam",

  PLACEHOLDERS_LAST_NAME: "Achternaam",
  PLACEHOLDERS_BIRTHDAY: "Verjaardag",
  PLACEHOLDERS_STREET: "Adres",

  PLACEHOLDERS_ADDRESS_TITLE: "Adres",

  PLACEHOLDERS_ZIP: "Postcode",

  PLACEHOLDERS_CITY: "Stad",
  PLACEHOLDERS_COUNTRY: "Land",

  PLACEHOLDERS_KVK_NUMBER_OPTIONAL: "KVK nummer (Optioneel)",
  PLACEHOLDERS_COMPANY_NAME_OPTIONAL: "Bedrijfsnaam (Optioneel)",
  PLACEHOLDERS_ACCOUNT_HEAD_LINE: "Jouw favoriete functie",

  PLACEHOLDERS_PLACE: "Plaats",
  PLACEHOLDERS_ACCOUNT_HOLDER_NAME: "Naam rekeninghouder",
  PLACEHOLDERS_ENTER_CONTRACT: "Contract aangaan",
  PLACEHOLDERS_IBAN: "IBAN",
  PLACEHOLDERS_ACCOUNT_NUMBER: "Rekeningnummer",
  PLACEHOLDERS_BTW_NUMBER: "BTW-nummer",
  PLACEHOLDERS_BANK_NAME: "Bank naam",
  PLACEHOLDERS_COMPANY_NAME: "Bedrijfsnaam",
  PLACEHOLDERS_EMPLOYEES: "Aantal werknemers",
  PLACEHOLDERS_POSITION: "Functie",
  PLACEHOLDERS_BUSINESS_BRANCH: "Zakelijke Branche",
  PLACEHOLDERS_ADD_DESCRIPTION: "Beschrijving toevoegen",
  PLACEHOLDERS_PERPOSAL: "Voorstel",
  PLACEHOLDERS_MESSAGE: "Bericht",
  PLACEHOLDERS_TELL_SOMETHING_ABOUT_YOURSELF: "Vertel iets over jezelf?",
  PLACEHOLDERS_ID_NUMBER: "ID/Paspoort nummer",
  PLACEHOLDERS_SALARY_HOURLY_RATE: "Uurtarief",
  PLACEHOLDERS_JOB_TITLE: "Functietitel",
  PLACEHOLDERS_EDUCATION_TITLE: "Jouw opleiding?",
  PLACEHOLDERS_ENTER_CATEGORY: "Voer categorie in",
  PLACEHOLDERS_ENTER_BRANCH: "Voer branche in",
  PLACEHOLDERS_ADD_SKILLS: "Vaardigheden toevoegen",
  PLACEHOLDERS_ADD_REASON: "Reden toevoegen",
  PLACEHOLDERS_TASK_NAME: "Taaknaam",
  PLACEHOLDERS_9_00: "09:00",
  PLACEHOLDERS_REASON: "Reden",

  PLACEHOLDERS_11_00: "11:00",
  PLACEHOLDERS_I_WILL_WORK_WHOLE_DAY:
    "Ik zal de hele dag werken voor kantoor...",
  PLACEHOLDERS_JOB_NAME: "Dienst naam",
  PLACEHOLDERS_JOB_DESCRIPTION: "Bericht voor werknemer",
  PLACEHOLDERS_REPORT_DESCRIPTION: "Rapport beschrijving",
  PLACEHOLDERS_STARTING_DATE: "Begin datum",
  PLACEHOLDERS_REPEAT_ENDING_DATE: "Eind datum",
  PLACEHOLDERS_START_TIME: "Begin tijd",
  PLACEHOLDERS_END_TIME: "Eind tijd",
  PLACEHOLDERS_ENDING_DATE: "Eind datum",
  PLACEHOLDERS_TIME_START: "Begin Tijd",
  PLACEHOLDERS_TIME_END: "Einde Tijd",
  PLACEHOLDERS_BREAK_TIME: "Pauze in minuten",
  PLACEHOLDERS_REVIEW: "Review van de medewerker",
  PLACEHOLDERS_MONTHLY_SALARY_PRICE_PER_HOUR:
    "Prijs per uur",
  PLACEHOLDERS_TRAVEL_EXPENSE: "Reiskosten",
  PLACEHOLDERS_WORKERS_NEEDED: "Aantal medewerkers nodig",
  PLACEHOLDERS_CLOTHES: "Verplichte kleding",
  PLACEHOLDERS_CLOTHES_DESCRIPTION: "Kleding beschrijving",
  PLACEHOLDERS_ADDRESS: "Adres",
  PLACEHOLDERS_GOOGLE_AUTFILL_ADDRESS: "Vul hier je adres in via Google automatisch invullen",
  PLACEHOLDERS_PHONE_NUMBER: "Voer uw telefoonnummer in",
  PLACEHOLDERS_JOB_TTTLE: "Jouw functie",
  PLACEHOLDERS_ENTER_COMPANY_NAME: "Voer bedrijfsnaam in",
  PLACEHOLDERS_ENTER_DEGREE_TITLE: "Voer de titel van het diploma in",
  PLACEHOLDERS_ENTER_INSTITUTE_NAME: "Voer de naam van het instituut in",
  PLACEHOLDERS_JOB_PROPOSAL: "Dienst voorstel",
  PLACEHOLDERS_ENTER_SHIFT: "Enter Shift",
  PLACEHOLDERS_SELECT_BRANCH: "Selecteer Branch",
  PLACEHOLDERS_SELECT_EMPLOYEE: "Selecteer Medewerker",
  PLACEHOLDERS_ADD_LINKEDIN_URL: "Voeg LinkedIn url in",
  PLACEHOLDERS_ADD_SKYPE_URL: "Voeg Instagram url in",

  PLACEHOLDER_PW: "Voer uw wachtwoord in",
  PLACEHOLDER_NUM_OF_ITEM: "Voer het aantal items in",
  PLACEHOLDER_SELECT: "Selecteer",

  PLACEHOLDER_ENTER_PHONE: "Voer uw telefoonnummer in",
  PLACEHOLDER_ENTER_POSTAL_CODE: "Voer uw postcode in",
  PLACEHOLDER_ENTER_ADDRESS: "Voer alstublieft uw adres in",
  PLACEHOLDER_SEARCH: "Zoek hier...",
  PLACEHOLDER_SEND_GOVERNMENT_ISSUED_LINK:
    "Stuur door de VOG link naar medewerker",
  PLACEHOLDER_ADD_BRANCH_NAME: "Voeg vestiging toe",
  PLACEHOLDER_SELECT_BRANCH: "Selecteer een vestiging",
  PLACEHOLDER_ADD_CATEOGRY_NAME: "Voeg categorie naam toe",
  PLACEHOLDER_ADD_SKILL_NAME: "Voeg de naam van de vaardigheid toe",


  // new one
  PLACEHOLDER_CURRENT_PASSWORD:"huidig ​​wachtwoord",
  PLACEHOLDER_NEW_PASSWORD:"nieuw paswoord",
}