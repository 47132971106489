export default {
ERROR_BREAK_TIME_REQUIRED: "Pauzetijd is vereist",
  ERROR_INVALID_PW:
     "Het wachtwoord moet minimaal 8 tekens lang zijn en moet minimaal één hoofdletter, één kleine letter, één cijfer en één speciaal teken bevatten",
  ERROR_PW_NOT_MATCH: "Wachtwoord en bevestigingswachtwoord komen niet overeen",
  ERROR_INVALID_PW_WEAK:
     "Wachtwoord bevat alfabet en getal en lengte moet 6 tot 12 zijn",
  ERROR_CATEGORY_REQUIRED: "Categorie is vereist",

  ERROR_APPLICATION_SUBMITTED: "Aanvraag kon niet worden ingediend",

  ERROR_REPEATED_REQUIRED: "Dienst herhaling is vereist",
  ERROR_REQUIRE_PW: "Wachtwoord is vereist",
  ERROR_REQUIRE_EMAIL: "E-mail is vereist",
  ERROR_REQUIRE_NAME: "Naam is verplicht",
  ERROR_REQUIRE_Maker: " is vereist",
  ERROR_REQUIRE_EMISSION: " is vereist",
  ERROR_INVALID_EMAIL: "Ongeldige e-mail",
  ERROR_INVALID_PROVINCE: "Ongeldige provincie",
  ERROR_PLEASE_TYPE: "Typ hier alstublieft...",
  ERROR_INVALID_ADMIN_NAME: "De lengte van de beheerdersnaam moet meer dan 3 zijn",
  ERROR_LOGIN_FAIL: "Inloggen mislukt",
  ERROR_SESSION_EXPIRE: "Sessie verlopen",
  ERROR_NETWORK: "Netwerkfout opgetreden",
  ERROR_UNKNOWN: "Er is iets misgegaan",
  ERROR_WRONG_CRED: "Uw inloggegevens komen niet overeen",
  ERROR_UNKOWN: "Er is iets misgegaan. Probeer het later opnieuw.",
  ERROR_INVALID_BRANCH: "Ongeldige branch",
  ERROR_INVALID_CATEGORY: "Ongeldige categorie",
  ERROR_INVALID_SKILLSET: "Ongeldige vaardigheid",

  ERROR_TITLE_REQUIRED: "Titel is vereist",
  ERROR_JOB_DESCRIPTION_REQUIRED: "Dienst omschrijving is vereist",
  ERROR_WORKER_TYPE_REQUIRED: "Werknemertype is vereist",
  ERROR_HIRING_URGENCY_REQUIRED: "Inhuur urgentie niveau is vereist",
  ERROR_WAGE_REQUIRED: "Loon is vereist",
  ERROR_ADDRESS_REQUIRED: "Adres is vereist",
  ERROR_CLOTHS_REQUIRED: "Kleding beschrijving is vereist",
  ERROR_TRAVEL_EXPENSE_REQUIRED: "Reiskosten zijn vereist",
  ERROR_WORKER_REQUIRED: "Aantal werknemers is vereist",
  ERROR_TIME_TO_START_REQUIRED: "Tijd om te starten is vereist",
  ERROR_TIME_TO_START_FORMAT: "Tijdformaat moet 09:00 zijn",
  ERROR_TIME_TO_END_REQUIRED: "Tijd om te eindigen is vereist",
  ERROR_TIME_TO_END_NOT_GREATER:
    "De tijd om te eindigen moet later zijn dan de begintijd",
  ERROR_STARTING_DATE_REQUIRED: "Startdatum is vereist",
  ERROR_STARTING_DATE_PAST: "Startdatum mag niet in het verleden zijn",
  ERROR_ENDING_DATE_REQUIRED: "Einddatum is vereist",
  ERROR_REPEATED_DAYS_REQUIRED: "Dagen herhalen is vereist",
  ERROR_REPEATED_WEEK_REQUIRED: "Herhalende week is vereist",
  ERROR_ENDING_DATE_PAST: "Einddatum mag niet in het verleden zijn",
  ERROR_ENDING_DATE_BEFORE_STARTING_DATE: "Einddatum is vereist",
  ERROR_HOURLY_RATE: "Uurtarief is vereist",

  ERROR_EMAIL_REQUIRED: "E-mail is vereist",
  ERROR_INVALID_DATE: "Selecteer data",
  ERROR_STATUS_REQUIRED: "Status is vereist",

  ERROR_INVOICE_PERIOD: "selecteer factuurperiode",

  ERROR_EMAIL_INVALID: "E-mail is ongeldig",
  ERROR_PW_REQUIRED: "Wachtwoord is vereist",
  ERROR_PW_INVALID: "Wachtwoord is ongeldig",
  ERROR_PW_FORMAT:
    "Wachtwoord moet minimaal één hoofdletter, één kleine letter, één nummer en één speciaal teken bevatten",
  ERROR_PW_LENGTH: "Wachtwoord moet minimaal 8 tekens lang zijn",

  ERROR_FIRSTNAME_REQUIRED: "Voornaam is vereist",
  ERROR_FIRSTNAME_INVALID: "Voornaam is ongeldig",
  ERROR_TITLE_INVALID: "Titel is ongeldig",
  ERROR_JOB_DESCRIPTION_INVALID: "Dienst omschrijving is ongeldig",
  ERROR_LASTNAME_REQUIRED: "Achternaam is vereist",
  ERROR_LASTNAME_INVALID: "Achternaam is ongeldig",
  ERROR_DOB_REQUIRED: "Geboortedatum is vereist",
  ERROR_DOB_INVALID: "Geboortedatum is ongeldig",
  ERROR_COMPANY_NAME_REQUIRED: "bedrijfsnaam is vereist",
  ERROR_EMPLOYEE_REQUIRED: "Aantal werknemers is vereist",
  ERROR_EMPLOYEE_INVALID: "Werknemer is ongeldig",
  ERROR_POSITION_REQUIRED: "Functie is vereist",
  ERROR_POSITION_INVALID: "Functie is ongeldig",
  ERROR_BRANCH_REQUIRED: "Branche is vereist",

  ERROR_STREET_REQUIRED: "Straat is vereist",
  ERROR_STREET_INVALID: "Straat is ongeldig",
  ERROR_ZIP_REQUIRED: "Postcode is vereist",
  ERROR_ZIP_INVALID: "Postcode is ongeldig",

  ERROR_CITY_REQUIRED: "City is required",
  ERROR_COUNTRY_REQUIRED: "Country is required",

  ERROR_PLACE_REQUIRED: "Plaats is vereist",
  ERROR_PLACE_INVALID: "Plaats is ongeldig",
  ERROR_SHIFT_REQUIRED: "Shift is vereist",

  ERROR_MOBILE_REQUIRED: "Mobiel nummer is vereist",

  ERROR_ACCOUNT_HOLDER_NAME_REQUIRED: "Naam accounthouder is vereist",
  ERROR_ACCOUNT_NUMBER_REQUIRED: "Rekeningnummer is vereist",
  ERROR_BANK_NAME_REQUIRED: "Banknaam is vereist",
  ERROR_IBAN_REQUIRED: "IBAN is vereist",
  ERROR_IBAN_FORMAT: "IBAN moet het juiste rekeningnummer hebben",

  ERROR_FILL_REQUIRED_THE_FIELDS: "Vul de verplichte velden in",

  ERROR_REPORT_REASON: "Reden voor melding is vereist",
  ERROR_REPORT_DESCRIPTION: "Reden voor melding is vereist",

  ERROR_MAX_THREE_CATEGORIES:
    "Maximaal 3 categorieën kunnen worden geselecteerd",

  ERRORS_ID_CARD_REQUIRED: "Voor- en achterkant van de ID-kaart is vereist",
  ERROR_TASK_REQUIRED: "Taak is vereist",
  ERROR_DATE_REQUIRED: "Datum is vereist",
  ERROR_RATING: "Beoordeling is vereist",
  ERROR_REVIEW: "Review is vereist",

  ERROR_AVAILABILITY_REQUIRED: "Beschikbaarheid is vereist",
  ERROR_TIMEFRAME_REQUIRED: "Tijdsperiode is vereist",
  ERROR_INVALID_AVAILABILITY: "Ongeldige beschikbaarheid",
  ERROR_INVALID_TIMEFRAME: "Ongeldige periode",
  ERROR_INVALID_START_TIMEFRAME: "De eindtijd moet later zijn dan de begintijd",

  ERROR_INVALID_END_TIMEFRAME: "Ongeldige eind tijd",

  ERROR_PHONE_INVALID: "Telefoonnummer is ongeldig",

  ERROR_PERPOSAL_DESCRIPTION_REQUIRED: "Beschrijving van het voorstel is vereist",
  ERROR_PERPOSAL_DESCRIPTION_INVALID: "Beschrijving van het voorstel is ongeldig",
  branch_not_allowed:"Het is niet toegestaan voor uw organisatie om diensten te plaatsen voor de door u geselecteerde categorie",


  // New one 

  ERROR_CU_PW_REQUIRED:"Voer uw huidige wachtwoord in",
  ERROR_NEW_PW_REQUIRED:"Voer uw nieuwe wachtwoord in",
  ERROR_CONFIRM_PW_REQUIRED:"Voer uw bevestigingswachtwoord in",
}