export default {
  SUCCESS_LOGIN: "Successfully Logged in",
  SUCCESS_PW_RESET: "Successfully email is sent",
  SUCCESS_CHANGE_PW: "Successfully password is changed",
  SUCCESS_EDIT_ACCOUNT: "Successfully update account infomation",
  SUCCESS: "Success",

  SUCCESS_ATTRIBUTE_ADDED: "Attribute added successfully",
  SUCCESS_ATTRIBUTE_DELETED: "Attribute deleted successfully",
  SUCCESS_ATTRIBUTE_UPDATED: "Attribute updated successfully",
  SUCCESS_SENT_LINK: "Link sent successfully",
  SUCCESS_ACCOUNT_ACTIVE: "Account active successfully",
  SUCCESS_ACCOUNT_INACTIVE: "Account inactive successfully",
  SUCCESS_BRANCH_ADDED: "Branch added successfully",
  SUCCESS_BRANCH_UPDATED: "Branch updated successfully",
  SUCCESS_BRANCH_DELETED: "Branch deleted successfully",
  SUCCESS_CATEGORY_ADDED: "Category added successfully",
  SUCCESS_CATEGORY_UPDATED: "Category updated successfully",
  SUCCESS_ORGANIZATION_UPDATED: "Organization updated successfully",

  SUCCESS_SKILL_ADDED: "Skill added successfully",
  SUCCESS_SKILL_UPDATED: "Skill updated successfully",
  SUCCESS_SKILL_DELETED: "Skill deleted successfully",
  SUCCESS_CATEGORY_DELETED: "Category deleted successfully",
  SUCCESS_EMPLOYEE_DECLINED: "Employee declined successfully",
  SUCCESS_EMPLOYEE_VERIFIED: "Employee verified successfully",
  REPORT_GENRATED: "Report genrated successfully",
  SUCCESS_DELETING_JOB: "Job deleted successfully",
  SUCCESS_JOB_UPDATED: "Job updated successfully",
  ERROR_DELETING_JOB: "Error in deleting job",

  SUCESS_JOB_EDITED: "Successfully updated job",
  SUCESS_JOB_POSTED: "Successfully Posted a new job",
  SUCESS_INVITIATION_SENT: "Worker Invited",
  SUCESS_HIRE: "Worker Hire successfully",

  SUCCESS_EMAIL_SENT: "Request sent successfully",
  JOB_SHIFT_UPDATED: "Shift updated successfully",
  REGISTRATION_SUCCESSFULLY_COMPLETED: "Registration successfully completed",
  PASSWORD_UPDATED_SUCCESSFULLY: "Password updated successfully",
  LOGIN_SUCESS: "Login successfully",

  SUCCESS_APPLICATION_SUBMITTED: "Application submitted successfully",

  SUCESS_INVOICE_GENERATED: "Invoice Generated successfully",
  SUCESS_INVOICE_PAID: "Invoice Paid successfully",
  SUCESS_JOB_DELETED: "Job Deleted successfully",
  SUCESS_PROFILE_UPDATED: "Profile Updated successfully",
  SUCESS_SETTINGS_UPDATED: "Settings Updated successfully",
  SUCESS_ACCOUNT_DELTED: "Account Deleted successfully",
  SUCESS_HIRE_APPLICANTS: "Hired applicant successfully",
  SUCESS_APPLIED: "Applied successfully",
  SUCESS_RATING: "You have rated worker successfully",
  SUCESS_CHANGE_HOURLY_RATE: "Hourly rate changed successfully",
  SUCESS_CONTRACT_START_AGAIN: "Contract started again successfully",
  SUCESS_JOB_SAVED: "Job Saved successfully",
  SUCESS_SCHEDULE_ADDED: "Schedule added successfully",
  SUCESS_SCHEDULE_UPDATED: "Schedule updated successfully",
  SUCESS_TASK_UPDATED: "Task updated successfully",
  SUCESS_SCHEDULE_DELETED: "Schedule deleted successfully",
  SUCESS_AVAILABILITY_ADDED: "Availability added successfully",
  SUCESS_AVAILABILITY_UPDATED: "Availability updated successfully",
  SUCESS_AVAILABILITY_DELETED: "Availability deleted successfully",
  SUCESS_COMPLETED_CONTRACT: "Contract completed successfully",
  SUCESS_CANCELED_CONTRACT: "Contract canceled successfully",
  SUCESS_PAUSED_CONTRACT: "Contract paused successfully",
  SUCESS_RESUMED_CONTRACT: "Contract resumed successfully",

  SUCESS_JOB_UPDATED: "Job updated successfully",
  SUCESS_ACCOUNT_CREATED:"Account created successfully",

}